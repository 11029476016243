.home-cardSummaryWidget-mainContainer{
    background-color: white;
    height: 100%;
    display: flex;
    border-radius: 15px;
    padding: 20px;
    box-sizing: border-box;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
}

.h-csw-mc-heading{
    font-weight: bold;
    height: 30%;
}

.h-csw-mc-ic-info{
    display: flex;
    justify-content: space-between;
    font-size: 11px;
    letter-spacing: 0.16em;
}

.h-csw-mc-ic-actions{
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
}

.h-csw-mc-ic-actionBtn{
    padding: 10px;
    width: 49%;
    font-weight: bold;
    border-radius: 6px;
    font-family: "Inter";
}


.h-csw-mc-ic-data{
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    font-weight: 600;
    font-size: 16px;
}
