.header-container{
    height : 48px;
}


/* home screen header styles */
.home-header-mainContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
    
}

.home-header-mainContainer-title{
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* session expiry screen header styles */
.sessionExpired-header-mainContainer{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-left: 20px;
    font-weight: bolder;
    box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;

}

.sessionExpired-backArrow-container{
    width: 140px;
    display: flex;
}

.backArrow{
    font-size: 15px !important;
}



