.home{
    height: 100%;
    
}


.home-cardAndBalance{
    width : 100%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}


.home-cardSummary{
    width: 100%;
    margin:auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.home-transactions{
    height : 400px;
    width: 100%;
    margin:auto;
    margin-top: 10px;
    margin-bottom: 10px;
}