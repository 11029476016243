.dateSelect{
	margin: 0px;
	border-radius: 8px;
    width: 100%;
}
.dateSelect-container{
	padding: 5px;
}
.dateSelect-container-box{
	display: flex;
}
.dateSelect-container-box-dropdown{
	/* display: flex; */
	flex: 1 1 0%;
    position: relative;
}
.dateSelect-container-box-dropdown-content{
	display: flex;
	margin: 10px;
	border-radius: 8px;
	border-width: 2px;
	border-style: groove;
	cursor: pointer;
}
.dateSelect-container-box-dropdown-content-text{
	padding: 12px;
}
.dateSelect-container-box-dropdown-content-imgBox{
	display: flex;
	flex: 1 1 0%;
	justify-content: flex-end;
	align-items: center;
	margin-right: 15px;
}
.dateSelect-container-box-dropdown-content-img{
	height: 8px;
	width: 16px;
}
.dateSelect-container-box-dropdown-contentClose-year, .dateSelect-container-box-dropdown-contentClose-month{
	display: none;
}
.dateSelect-container-box-dropdown-contentOpen-year{
	position: absolute;
	width: 98%;
	left: 2px;
	top: 55px;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
	z-index: 10;
	overflow: hidden;
}
.dateSelect-container-box-dropdown-contentOpen-month{
	position: absolute;
	width: 98%;
    left: 3px;
	top: 55px;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
	z-index: 10;
	overflow: hidden;
}
.dateSelect-container-box-dropdown-contentOpen-month-scroll{
	width: 100%;
	height: 100%;
	overflow-y: scroll;
    padding-right: 17px; 
    box-sizing: content-box;
}
.dateSelect-container-box-dropdown-contentOpen-yearSelected-text,.dateSelect-container-box-dropdown-contentOpen-monthSelected-text{
	margin-top: 5px;
	width: 100%;
	text-align: center;
	padding: 5px;
	cursor: pointer;
	font-size: 14px;
}
.dateSelect-container-box-dropdown-contentOpen-yearNotSelected-text,.dateSelect-container-box-dropdown-contentOpen-monthNotSelected-text{
	margin-top: 5px;
	width: 100%;
	text-align: center;
	padding: 5px;
	cursor: pointer;
	font-size: 14px;
}
.dateSelect-button{
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	margin: 12px 10px;
	border-radius: 8px;
}
.dateSelect-button-text{
	padding: 10px;
	font-size: 16px;
	font-weight: 600;
	color:#030303
}