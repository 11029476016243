.grow {
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-name: grow;
  animation-delay: 2s;
  animation-timing-function: linear;
  position: absolute;
  height: 261px;
  width: 165px;
  background-size: 100% 100%;
  color: #333;
  opacity: 0.15;
  border-radius: 12px;
  box-shadow: 4px 4px #f8f8f8;
  z-index: -1;
}
.grow-horizontal {
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-name: grow-horizontal;
  animation-delay: 2s;
  animation-timing-function: linear;
  position: absolute;
  width: 261px;
  height: 165px;
  background-size: 100% 100%;
  color: #333;
  opacity: 0.15;
  border-radius: 12px;
  box-shadow: 4px 4px #f8f8f8;
  z-index: -1;
}
@keyframes grow {
  0% {
    height: 261px;
    width: 165px;
  }
  5% {
    height: 264px;
    width: 170px;
  }
  10% {
    height: 269px;
    width: 175px;
  }
  20% {
    height: 280px;
    width: 178px;
  }
  25% {
    height: 272px;
    width: 174px;
  }
  30% {
    height: 270px;
    width: 172px;
  }
  35% {
    height: 280px;
    width: 180px;
  }
  40% {
    height: 272px;
    width: 175px;
  }
  45% {
    height: 280px;
    width: 175px;
  }
  50% {
    height: 270px;
    width: 170px;
  }
  55% {
    height: 268px;
    width: 170px;
  }
  60% {
    height: 261px;
    width: 165px;
  }
  65% {
    height: 261px;
    width: 165px;
  }
  100% {
    height: 261px;
    width: 165px;
  }
}

.grow-outline {
  animation-iteration-count: infinite;
  animation-duration: 4s;
  animation-name: grow-outline;
  animation-delay: 2s;
  animation-timing-function: linear;
  position: absolute;
  height: 261px;
  width: 165px;
  border: 1px solid #333;
  border-radius: 12px;
}

@keyframes grow-horizontal {
  0% {
    width: 261px;
    height: 165px;
  }
  5% {
    width: 264px;
    height: 170px;
  }
  10% {
    width: 269px;
    height: 175px;
  }
  20% {
    width: 280px;
    height: 178px;
  }
  25% {
    width: 272px;
    height: 174px;
  }
  30% {
    width: 270px;
    height: 172px;
  }
  35% {
    width: 280px;
    height: 180px;
  }
  40% {
    width: 272px;
    height: 175px;
  }
  45% {
    width: 280px;
    height: 175px;
  }
  50% {
    width: 270px;
    height: 170px;
  }
  55% {
    width: 268px;
    height: 170px;
  }
  60% {
    width: 261px;
    height: 165px;
  }
  65% {
    width: 261px;
    height: 165px;
  }
  100% {
    width: 261px;
    height: 165px;
  }
}

@keyframes grow-outline {
  0% {
    height: 261px;
    width: 165px;
  }
  5% {
    height: 264px;
    width: 170px;
  }
  10% {
    height: 269px;
    width: 175px;
  }
  20% {
    height: 280px;
    width: 178px;
  }
  45% {
    height: 290px;
    width: 190px;
    border: 0px none #333;
  }
  /* 30% {
    height: 270px;
    width: 172px;
  }
  35% {
    height: 280px;
    width: 180px;
  }
  40% {
    height: 272px;
    width: 175px;
  }
  45% {
    height: 280px;
    width: 175px;
  }
  50% {
    height: 270px;
    width: 170px;
  }
  55% {
    height: 268px;
    width: 170px;
  }
  60% {
    height: 261px;
    width: 165px;
  } */
  65% {
    border: 0px none #333;
  }
  100% {
    height: 261px;
    width: 165px;
    /* border: 4px dotted blue; */
  }
}

.zoom-vertical {
  display: flex;
  align-items: center;
  justify-content: center;
  animation-iteration-count: infinite;
  animation-duration: 4s;
  animation-name: zoom;
}
@keyframes zoom {
  0% {
    height: 261px;
    width: 165px;
  }
  /* 5% {
    height: 264px;
    width: 168px;
  }
   10% {
    height: 268px;
    width: 172px;
  }
  20% {
    height: 280px;
    width: 178px;
  }
  25% {
    height: 272px;
    width: 174px;
  }
  30% {
    height: 270px;
    width: 172px;
  } */
  35% {
    height: 255px;
    width: 160px;
  }
  /* 40% {
    height: 272px;
    width: 175px;
  }
  45% {
    height: 280px;
    width: 175px;
  }
  50% {
    height: 270px;
    width: 170px;
  }
  55% {
    height: 268px;
    width: 170px;
  } */
  60% {
    height: 261px;
    width: 165px;
  }
  65% {
    height: 261px;
    width: 165px;
  }
  100% {
    height: 261px;
    width: 165px;
  }
}
.tap {
  z-index: 1;
}

.flip {
  display: flex;
  align-items: center;
  justify-content: center;
  animation-iteration-count: 4;
  animation-duration: 4s;
  animation-name: flip;
}
@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }
  /* 5% {
    height: 264px;
    width: 168px;
  }
   10% {
    height: 268px;
    width: 172px;
  }
  20% {
    height: 280px;
    width: 178px;
  }
  25% {
    height: 272px;
    width: 174px;
  }
  30% {
    height: 270px;
    width: 172px;
  } */
  /* 35% {
    transform: rotateY(180deg);
  } */
  /* 40% {
    height: 272px;
    width: 175px;
  }
  45% {
    height: 280px;
    width: 175px;
  }
  50% {
    height: 270px;
    width: 170px;
  }
  55% {
    height: 268px;
    width: 170px;
  } */
  60% {
    transform: rotateY(360deg);
  }
  65% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

.tilt-shaking {
  display: flex;
  align-items: center;
  justify-content: center;
  animation-iteration-count: 4;
  animation-duration: 1.5s;
  animation-name: tilt-shaking;
  animation-delay: 1s;
}
@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(1deg);
  }
  20% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-1deg);
  }
  40% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(1deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.container-horizontal {
  position: relative;
  width: 165px;
  height: 261px;
  animation: scale 2s ease-in-out 2;
  overflow: hidden;
  background-color: transparent;
  border-radius: 12px;
}
.container-vertical {
  position: relative;
  width: 261px;
  height: 165px;
  animation: scale 2s ease-in-out 2;
  overflow: hidden;
  border-radius: 12px;
  background-color: transparent;
}

.strip {
  position: absolute;
  display: block;
  top: -120px;
  left: 0;
  width: 0px;
  height: 0px;
  transform: rotate(45deg);
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.393),
    rgba(255, 255, 255, 0.393)
  );
  z-index: 1;
  animation: slide-strip 2s ease-in-out 2;
}

.rectangle {
  position: absolute;
  height: 261px;
  width: 165px;
  z-index: 0;
  background-color: transparent;
  border-radius: 12px;
  /* animation: scale 2s ease-in-out 2; */
}
.rectangle-vertical {
  position: absolute;
  width: 261px;
  height: 165px;
  background-color: transparent;
  z-index: 0;
  border-radius: 12px;
  /* animation: scale 2s ease-in-out 2; */
}

@keyframes slide-strip {
  0% {
    transform: translateX(-800%) rotate(45deg);
    display: block;
    width: 30px;
    height: 600px;
  }
  100% {
    transform: translateX(800%) rotate(45deg);
    display: block;
    width: 30px;
    height: 600px;
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
