.flex-column-center{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
}
.home-widget-mainContainer{
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 5%;
  /* justify-content: center; */
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
}

.hf-slider {
  -webkit-appearance: none;
  appearance: none;
  background: grey;
  cursor: pointer;
  width: 15rem;
  background-repeat: no-repeat;
  border-radius: 0.5rem;
  background-image: linear-gradient(red, red);
}

.hf-slider:hover {
  outline: none;
}

/***** Webkit - Chrome, Safari, Opera and Edge Chromium styles *****/
.hf-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
}

/******** Firefox styles ********/
.hf-slider::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 0; /*Removes default border-radius that FF applies*/
}

/******** common styles for webkit and moz ********/

/* can't use commas to combine vendor-prefixed selectors */
.hf-slider::-webkit-slider-runnable-track {
  background: transparent;
  height: 0.3rem;
}
.hf-slider::-moz-range-track {
  background: transparent;
  height: 0.3rem;
}

.hf-slider::-webkit-slider-thumb {
  background-color: #FFFFFF;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 3.84px;
  width: 1rem;
  height: 1rem;
  margin-top: -5.5px;
}
.hf-slider::-moz-range-thumb {
  background-color: #FFFFFF;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 3.84px;
  width: 1rem;
  height: 1rem;
  margin-top: -5.5px;
}

.hf-slider:hover::-webkit-slider-thumb {
  margin-top: -7px;
  height: 1.2rem;
  width: 1.2rem;
}
.hf-slider:hover::-moz-range-thumb {
  margin-top: -7px;
  height: 1.2rem;
  width: 1.2rem;
}