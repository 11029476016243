.hf-toggle-container {
    display: flex;
    flex-direction: row;
}

.hf-toggle-label {
    margin-right: 10px;
}

.hf-toggle-parent {
    position: relative;
    width: 45px;
    height: 20px;
}

.hf-toggle-switch {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.hf-toggle-switch::before {
    position: absolute;
    content: '';
    height: 23px;
    width: 23px;
    left: -1px;
    bottom: -1px;
    background-color: #fafafa;
    box-shadow: 1px 1px 1px 1px grey;
    border-radius: 50%;
    transition: transform 0.3s;
}

.hf-toggle {
    display: none;
}

.hf-toggle:checked + .hf-toggle-switch {
    background-color: purple
}

.hf-toggle:checked + .hf-toggle-switch::before {
    transform: translateX(26px);
}