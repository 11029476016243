.home-transactionWidget-mainContainer{
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2.22px;
}

.transactionWidget-emptyTransactionMessage{
    margin-top: 20px;
    font-size: 14px;
}

.transactionWidget-emptyTransactions-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
}

.trasactionWidget-heading{
    padding: 20px;
    font-weight: bold;
}