.sessionExpired{
    min-height: inherit;
    display: flex;
    flex-direction: column;
}

.sessionExpired-content{
    flex-grow : 1;
    position: relative;
    width: fit-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    /* justify-content: center; */
}
.sessionExpired-imgContainer{
    margin-top: 150px;
    width: fit-content;
    height: fit-content;
    padding: 28px;
    border-radius: 100px;
}

.sessionExpired-headText{
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    padding-top : 25px;
}

.sessionExpired-secondaryText{
    text-align: center;
    padding-top: 15px;
    font-size: 16px;
}   

.sessionExpired-content > img{
    margin-top: 50%;
}