.home-cardAndBalance-mainContainer{
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 15px;
}

.home-cardAndBalanceWidget-cardContainer{
    width: 48%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.home-cardAndBalanceWidget-rewardsSection{
    width: 47%;
    height: 90%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgb(0 0 0 / 22%) 0px 1px 2.22px;
}

.h-c-r-icon{
    font-size: 150px !important;
}

.h-c-r-navigation{
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 80%;
    justify-content: space-around;
}

.h-c-r-navigation-icon{
    font-size: 15px !important;
}