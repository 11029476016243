.analyseSpends-dateSelect{
    margin: 16px;
	z-index: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.analyseSpends-dateSelect > select {
    height: 35px;
}

.analyseSpends-content{
	display: flex;
	flex-direction: column;
	margin: 20px auto;
	align-items: center;
}
.analyseSpends-content-text{
	font-size: 16px;
	font-weight: 600;
}
.analyseSpends-content-amount{
	font-weight: bold;
	font-size: 32px;
}

.analyseSpends-widget-header{
	font-size: 16px;
	font-family: Inter;
	font-weight: 500;
	width: 65%;
	margin-bottom: 10px
}
.analyseSpends-widget-transaction-content{
	margin-bottom: 14px;
	margin-left: 15px;
	width: 80%;
	flex: auto;
}
.analyseSpends-widget-transaction-content-div-1, .analyseSpends-widget-transaction-content-div-2{
	display: flex;
	align-items: center;
}
.analyseSpends-widget-transaction-content-amount,.analyseSpends-widget-transaction-content-countOfTxn{
	margin-left: auto;
	margin-bottom: 5px;
	display: flex;
	align-items: center;
}
.analyseSpends-widget-transaction-content-amount-text{
	color:#303030;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
}
.analyseSpends-widget-transaction-content-percentage{
	color: #7B7B7B;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
}
.analyseSpends-widget-transaction-content-countOfTxn-label{
	background-color: #F0EFF5;
	border-radius: 3px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	cursor: pointer;
	padding: 3px;
    letter-spacing: "0.04em";
    color: "#7B7B7B";
}

.analyseSpends-widget-icon-container{
	margin-bottom: 16px;
}

.analyseSpends-noSpends{
	padding: 16px;
	width: 100%;
	border-radius: 12px;
	box-shadow:rgb(0 0 0 / 20%) 0px 1px 1.41px ;
	margin: 24px auto;
}

.analyseSpends-noSpends-content{
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.analyseSpends-noSpends-content-img{
	width: 185px;
	height: 122px;
	margin-bottom: 24px;
}

.analyseSpends-noSpends-content-text-1{
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}
.analyseSpends-noSpends-content-text-2{
	margin-top: 5px;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
}
.analyseSpends, .spendCategory{
	padding: 0px 16px;
}

.spendCategory-content{
	margin: 5px auto 0px auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.spendCategory-content-categoryText{
	margin: 5px 0px;
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;line-height: 24px;
	letter-spacing: 0.02em;
	color: #303030;
}
.spendCategory-content-amount{
	font-size: 32px;
	font-weight: bold;
}

.spendCategory-content-date{
	font-size: 14px;
	font-weight: 500;
	opacity: 0.8;
	font-family: Inter;
	font-style: normal;	
}
.analyseSpends-loader, .spendCategory-loader{
	display: flex;
	justify-content: center;
	margin-top: 20px;
}
.analyseSpends-widget, .spendCategory-widget{
	padding: 16px;
	width: 100%;
	margin: 20px auto 24px auto;
	border-radius: 8px;
	border-width: 1px;
	border-color: #F8F8F8;
	box-shadow: rgb(0 0 0 / 20%) 0px 1px 1.41px;
}
.analyseSpends-widget-transaction,.spendCategory-widget-transaction{
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 0px solid #F0F0F0;

}
.spendCategory-widget-transaction-icon-container{
	margin-bottom: 16px;
	margin-right: 5%;
}
.spendCategory-widget-transaction-content{
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 2 1 0%;
}
.spendCategory-widget-transaction-content-description{
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
}
.spendCategory-widget-transaction-content-date{
	margin-top: 5px;
	color: #7B7B7B;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
}
.spendCategory-widget-transaction-content-debitAmount{
	color:#303030;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
}

.spendCategory-widget-transaction-content-creditAmount{
	color:#303030;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
}
